<template>
    <div class="custom-input">
        <label class="input-label" v-if="$validate.DataValid(label)">{{ label }}</label>
        <v-menu 
            ref="datePickerMenu"
            v-model="datePickerOpen"
            transition="scale-transition" 
            offset-y
            min-width="auto"
            :close-on-content-click="false"
            :return-value.sync="date"
            :disabled="disabled"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :append-icon="icon.mdiCalendarMinus"
                    persistent-placeholder
                    :value="dateFormat()"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    single-line
                    :rules="rules"
                    :hide-details="hideDetails"
                    :placeholder="placeholder"
                    :dense="dense"
                    :class="inputClass"
                    @click:clear="updateDateValueFromAction(true)"
                    clearable
                ></v-text-field>
            </template>
            <v-date-picker
                no-title
                scrollable
                v-model="date"
                :first-day-of-week="0"
                :day-format="$formatter.dayFormat"
                locale="zh-HK"
                @input="updateDateValue"
                :type="isYearMonthOnly ? 'month' : 'date'"
                :range="isDateRange"
                :readonly="isReadOnly"
                :min="dateMin"
                :max="dateMax"
            >
                <template v-if="!disabledActionButtons">
                    <v-spacer></v-spacer>
                    <div class="d-flex justify-space-between" style="width: 100%;">
                        <v-btn min-width="max-content" depressed small text color="error" @click="datePickerOpen = false">
                            <v-icon class="mr-1" small> {{icon.mdiClose}} </v-icon>取消
                        </v-btn>
                        <v-btn min-width="max-content" depressed small text color="primary" @click="updateDateValueFromAction(false)">
                            <v-icon class="mr-1" small> {{icon.mdiCheck}} </v-icon>確認
                        </v-btn>
                    </div>
                </template>
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>

import {
    mdiCalendarMinus,
    mdiClose,
    mdiCheck
} from '@mdi/js'

export default {
    name: 'FormDatePicker',
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },
        dateValue: { // YYYY-mm-dd | YYYY-mm
            type: String | Array,
            required: true,
            default: '', 
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        isYearMonthOnly:  {
            type: Boolean,
            required: false,
            default: false
        },
        isDateRange:  {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledActionButtons: {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        },
        disablePast: {
            type: Boolean,
            required: false,
            default: false
        },
        disableFuture: {
            type: Boolean,
            required: false,
            default: false
        },
        customInputClass: {
            type: String,
            required: false,
            default: ''
        },
        minDate: {
            type: String, // YYYY-MM-DD
            default: ''
        },
        maxDate: {
            type: String, // YYYY-MM-DD
            default: ''
        },
        customRules: {
            type: Array,
            required: false,
            default: () => [],
        },
        dispatchUpdateOnChange: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        rules() {
            return [
                ...this.customRules,
                v => {
                    return this.required ? (this.$validate.required(v) || '此位置不能留空') : true
                },
            ];
        },
        dateMin() {
            if (this.$validate.DataValid(this.minDate)) {
                return this.minDate;
            } else if (this.disablePast) {
                return this.$formatter.formatDate(new Date());
            }

            return undefined;
        },
        dateMax() {
            if (this.$validate.DataValid(this.maxDate)) {
                return this.maxDate;
            } else if (this.disableFuture) {
                return this.$formatter.formatDate(new Date());
            }

            return undefined;
        },
        isReadOnly() {
            return this.readonly || this.$store.getters.isLoading
        },
        inputClass() {
            let classObj = {
                'pointer-none': this.$store.getters.isLoading || this.disabled || this.readonly,
            };
            if (this.$validate.DataValid(this.customInputClass)) {
                const arr = this.customInputClass.split(' ');
                arr.forEach(name => {
                    if (this.$validate.DataValid(name.trim())) {
                        classObj[name.trim()] = true;
                    }
                })
            }

            return classObj;
        }
    },
    data: () => ({
        icon: {
            mdiCalendarMinus,
            mdiClose,
            mdiCheck
        },
        datePickerOpen: false,
        date: null
    }),
    watch: {
        dateValue: {
            handler(val) {
                this.date = val;
            },
            immediate: true
        },
    },
    methods: {
        updateDateValue(val) {
            this.$emit('date-clicked', val);
            if (this.disabledActionButtons) {
              this.updateDateValueFromAction();
            }
        },
        updateDateValueFromAction(clear) {
            if (clear === true) {
                this.date = this.isDateRange ? [] : '';
            }

            let _date = this.date;
            if (this.isDateRange) {
                if (this.date.length === 2 && new Date(this.date[0]).getTime() > new Date(this.date[1]).getTime()) {
                    _date = [this.date[1], this.date[0]]
                }
            }

            this.$refs.datePickerMenu.save(_date)
            this.$emit('update:dateValue', _date);
            this.$emit('changed', _date);
            if (this.dispatchUpdateOnChange === true) {
                this.$store.dispatch('setDataIsUpdated', true);
            }
            this.datePickerOpen = false;
        },
        dateFormat() {
            if (!this.$validate.DataValid(this.date)) {
                return '';
            }
           
            if (this.isDateRange) {
                if (Array.isArray(this.date)) {
                    let _date = this.date;
                    if (this.date.length === 2 && new Date(this.date[0]).getTime() > new Date(this.date[1]).getTime()) {
                        _date = [this.date[1], this.date[0]];
                    }
                    return `${_date[0]} (${this.$formatter.displayWeekday(_date[0])})${_date.length > 1 ? ` - ${_date[1]} (${this.$formatter.displayWeekday(_date[1])})` : ''}`
                }
            } else if (this.isYearMonthOnly) {
                return this.date;
            }

            return `${this.date} (${this.$formatter.displayWeekday(this.date)})`;
    
        },
    },
}
</script>

